<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Stores</span>
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="add"
          v-if="$check(51)"
          style="float: right; position: relative; bottom: 10px"
          >添加</el-button
        >

        <el-input
          v-model="filterData.name"
          placeholder="名称搜索"
          clearable
          @input="query()"
          style="
            float: right;
            width: 250px;
            margin-right: 10px;
            position: relative;
            bottom: 10px;
          "
        ></el-input>

        <el-select
          clearable
          style="float: right; margin-right: 10px; position: relative; bottom: 10px"
          @change="query(1)"
          v-model="filterData.status"
          placeholder="对接状态"
        >
          <el-option key="0" label="未对接" value="0"> </el-option>
          <el-option key="1" label="对接成功" value="1"> </el-option>
        </el-select>

        <el-select
          clearable
          style="float: right; margin-right: 10px; position: relative; bottom: 10px"
          @change="query(1)"
          v-model="filterData.tag"
          placeholder="对接平台"
        >
          <el-option
            v-for="(option, index) in tags"
            :key="index"
            :label="option"
            :value="index + 1"
          >
          </el-option>
        </el-select>
      </div>
      <el-table v-loading="loading" id="table" :data="tableData" size="mini" style="width: 100%">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="id" label="ID" width="40"></el-table-column>
        <el-table-column v-if="tag == 1" prop="description" width="160" label="Plateform">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.tag == 1" type="success">Shopify</el-tag>
            <el-tag v-if="scope.row.tag == 2" type="success">Commission Junction</el-tag>
            <el-tag v-if="scope.row.tag == 3" type="success">Rakuten Advertising</el-tag>
            <el-tag v-if="scope.row.tag == 4" type="success">ShareaSale</el-tag>
            <el-tag v-if="scope.row.tag == 5" type="success">Commission Factory</el-tag>
            <el-tag v-if="scope.row.tag == 6" type="success">Impact</el-tag>
            <el-tag v-if="scope.row.tag == 7" type="success">Skimlinks</el-tag>
            <el-tag v-if="scope.row.tag == 8" type="success">Awin</el-tag>
            <el-tag v-if="scope.row.tag == 9" type="success">Flex Offers</el-tag>
            <el-tag v-if="scope.row.tag == 10" type="success">Sovrn / Commerce</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Store" width="180">
          <template slot-scope="scope">
            {{truncate(scope.row.name, 20)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="shop"
          v-if="tag == 1"
          label="Store Url"
          width="260"
        ></el-table-column>
        <el-table-column v-if="tag == 1" prop="description" width="130" label="Feed Status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success">Connected</el-tag>
            <el-tag v-else>Not Connected</el-tag>
          </template>
        </el-table-column>
        <el-table-column label=".cn" width="60">
          <template slot-scope="scope">
              {{ scope.row.approved_for_cn ? 'yes' : 'no '}}
          </template>
        </el-table-column>
        <el-table-column label=".com" width="60">
            <template slot-scope="scope">
              {{ scope.row.approved_for_intl ? 'yes' : 'no '}}
          </template>
        </el-table-column>
        <el-table-column
          v-if="tag == 1"
          prop="refresh_time"
          width="170"
          label="Last Update"
        >
          <template slot-scope="scope">
            {{ formatDateString(scope.row.refresh_time, true) }}
          </template>
        </el-table-column>

        <el-table-column v-if="tag == 1" prop="products_count" width="80" label="Products" />
          
        <el-table-column label="Actions" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
              <i class="el-icon-edit primary" @click="edit(scope.row)"></i>
            </el-tooltip>

            <el-tooltip
              v-if="scope.row.status == 1 && scope.row.update_status == 0"
              class="item"
              effect="dark"
              content="更新"
              placement="top-start"
            >
              <i class="el-icon-refresh warning" @click="refresh(scope.row)"></i>
            </el-tooltip>

            <el-tooltip
              v-if="scope.row.status == 1 && scope.row.update_status == 1"
              class="item"
              effect="dark"
              content="更新中..."
              placement="top-start"
            >
              <i class="el-icon-loading warning"></i>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
              <i class="el-icon-delete danger" @click="remove(scope.row.id)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            :page-size="total"
            background
            layout="total,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog :title="title + '品牌'" :visible.sync="dialogFormVisible">
      <el-card v-if="dialogFormVisible">
        <!-- <div slot="header">
          <span>添加分类</span>
        </div> -->
        <el-form :model="form" ref="form" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item
                v-if="tag == 1"
                label="对接平台"
                prop="tag"
                :rules="[{ required: true, message: '请选择对接平台' }]"
              >
                <el-select v-model="form.tag">
                  <el-option :key="1" :value="1" label="Shopify"></el-option>
                  <el-option key="2" :value="2" label="Commission Junction"></el-option>
                  <el-option key="3" :value="3" label="Rakuten Advertising"></el-option>
                  <el-option key="4" :value="4" label="ShareaSale"></el-option>
                  <el-option key="5" :value="5" label="Commission Factory"></el-option>
                  <el-option key="6" :value="6" label="Impact"></el-option>
                  <el-option key="7" :value="7" label="Skimlinks"></el-option>
                  <el-option key="8" :value="8" label="Awin"></el-option>
                  <el-option key="9" :value="9" label="Flex Offers"></el-option>
                  <el-option key="10" :value="10" label="Sovrn / Commerce"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="tag == 1"
                label="币种"
                prop="tag"
                :rules="[{ required: true, message: '请选择对接平台' }]"
              >
                <el-select v-model="form.currency" filterable>
                  <el-option
                    v-for="(item, index) in currencies"
                    :key="index"
                    :value="item"
                    :v-once="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="品牌名称"
            prop="name"
            :rules="[{ required: true, message: '品牌名称不能为空' }]"
          >
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="品牌分类"
            prop="category"
            :rules="[{ required: true, message: '品牌分类不能为空' }]"
          >
            <el-input v-model="form.category" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item
            label="品牌简介"
            prop="description"
            :rules="[{ required: true, message: '品牌简介不能为空' }]"
          >
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.description"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="店铺地址"
            prop="shop"
            v-if="tag == 1"
            :rules="[{ required: true, message: '店铺地址不能为空' }]"
          >
            <el-input v-model="form.shop" autocomplete="off"></el-input>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item v-if="form.tag == 1" label="API密钥" prop="params.api_key">
            <el-input v-model="form.params.api_key" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag == 1" label="API加密密钥" prop="params.api_secret">
            <el-input v-model="form.params.api_secret" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item
            v-if="form.tag == 1"
            label="access_token"
            prop="params.access_token"
          >
            <el-input disabled v-model="form.params.access_token" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag != 1" label="请求方式" prop="params.ftp_protocol">
            <el-input v-model="form.params.ftp_protocol" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag != 1" label="ftp地址" prop="params.ftp_url">
            <el-input v-model="form.params.ftp_url" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag != 1" label="ftp用户名" prop="params.ftp_user">
            <el-input v-model="form.params.ftp_user" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag != 1" label="ftp密码" prop="params.ftp_password">
            <el-input v-model="form.params.ftp_password" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag != 1" label="文件路径" prop="params.ftp_file">
            <el-input v-model="form.params.ftp_file" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="form.tag != 1" label="Condition" prop="params.condition">
            <el-input v-model="form.params.condition" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label='Approved for'>
             <input
                type="checkbox"
                v-model='form.approved_for_cn' 
                label=".cn" 
                true-value="1" 
                false-value="0" />.cn
              <input
                type="checkbox"
                v-model='form.approved_for_intl' 
                label=".com" 
                true-value="1" 
                false-value="0" />.com
          </el-form-item>

          <!-- <el-row>
            <el-col :span="12"
              ><el-form-item
                label="图⽚1"
                prop="visual1"
                :rules="[{ required: true, message: '产品数量不能为空' }]"
              >
                <ImageUpload v-model="form.visual1"></ImageUpload> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item
                label="图⽚2"
                prop="visual2"
                :rules="[{ required: true, message: '产品数量不能为空' }]"
              >
                <ImageUpload v-model="form.visual2"></ImageUpload> </el-form-item
            ></el-col>
          </el-row> -->
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      style="zoom: 0.6"
      center
      fullscreen
      title="选择商品"
      :visible.sync="dialogFormVisible1"
    >
      <el-card>
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData1"
          style="width: 100%"
          size="small"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="product_type" label="分类" width="100"></el-table-column>
          <el-table-column prop="title" label="主标题" width="240"></el-table-column>
          <el-table-column prop="handle" label="副标题"></el-table-column>
          <el-table-column prop="body_html" label="描述"></el-table-column>
          <el-table-column label="主题封面">
            <template slot-scope="scope">
              <el-image
                :src="scope.row.images[0]"
                style="height: 100px; width: 100px"
              ></el-image>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="24" style="text-align: center; margin-top: 10px">
            <el-pagination
              background
              layout="total,prev, pager, next"
              @current-change="pageChange1"
              :current-page="filterData1.page"
              :total="total1"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="submit1()">添加到商品库</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import truncate from "../../lib/truncate.ts"
import formatDateString from '../../lib/formatDateString.ts'
export default {
  //   components: { ImageUpload },
  data() {
    return {
      tags: [
        "Shopify",
        "Commission Junction",
        "Rakuten Advertising",
        "ShareaSale",
        "Commission Factory",
        "Impact",
        "Skimlinks",
        "Awin",
        "Flex Offers",
        "Sovrn / Commerce",
      ],
      items: [],
      title: "添加",
      loading: false,
      total: 0,
      total1: 0,
      rule: [],
      filterData: { page: 1 },
      filterData1: { id: 0, page: 1 },
      tableData: [],
      tableData1: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      form: { params: {} },
      products: [],
      tag: 1,
      currencies: [],
    };
  },

  created() {
    if (this.$route.query.tag) {
      this.tag = this.$route.query.tag;
      this.filterData.tag = this.tag;
    }

    this.query();
    this.query2();
  },

  methods: {
    formatDateString,
    truncate(string, n) {
      return truncate(string, n)
    },
    handleSelectionChange(val) {
      this.products = val;
    },
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },

    pageChange1(page) {
      this.filterData1.page = page;
      this.query1();
    },

    select_products(id) {
      this.dialogFormVisible1 = true;
      this.filterData1.id = id;
      this.query1();
    },

    query1() {
      this.loading = true
      this.$http
        .post("/admin/store/products", this.filterData1)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.tableData1 = res.data.data;
            this.total1 = res.data.total;
          }
        })
        .catch(() => {});
    },

    query2() {
      this.$http
        .post("/admin/store/currencies")
        .then((res) => {
          if (res.status == 200) {
            this.currencies = res.data.data;
          }
        })
        .catch(() => {});
    },
    query(page) {
      this.loading = true
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/store/list", this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/store/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    edit(row) {
      this.title = "修改";
      this.form = row;
      if (!this.form["params"]) {
        this.form["params"] = {};
      }
      this.dialogFormVisible = true;
    },

    add() {
      this.title = "添加";
      this.form = { params: {}, tag: this.tag };
      this.dialogFormVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/store/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = { params: {} };
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    refresh(info) {
      this.$http.post("/admin/store/refresh", { id: info.id }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.query();
        }
      });
    },

    submit1() {
      if (this.products.length > 0) {
        let products = [];
        this.products.forEach((item) => {
          products.push({
            tag: 1,
            stock: 1,
            store_id: item.store_id,
            product_id: item.id,
            product_type: item.product_type,
            title: item.title,
            price: parseFloat(item.price),
            handle: item.handle,
            body_html: item.body_html,
            product_type_en: item.product_type,
            title_en: item.title,
            handle_en: item.handle,
            body_html_en: item.body_html,
            color: item.color,
            size: item.size,
            material: item.material,
            images: item.images,
            options: ["default"],
            variants: [{ stock: 1, price: item.price }],
          });
        });

        this.$http.post("/admin/products/save", products).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.dialogFormVisible = false;
            this.form = {};
            this.query();
          }
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.item {
  font-size: 20px;
  margin: 0 5px;
  cursor: pointer;
}
.primary {
  color: #409eff;
}

.warning {
  color: #e6a23c;
}

.danger {
  color: #f56c6c;
}
</style>
